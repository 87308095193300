<template >
  <!-- -->
  <div>
    <v-app-bar class="globalRadiusAppBar" app :dark="$store.state.isDarkMode" height="40"
      :class="{ appBarRtl: $i18n.locale == 'en' }">


      <v-col md="7">
        <v-row>
          <div style="width: 30px;"></div>
          <div style="width: 120px">
          <v-fade-transition mode="out-in">
            <v-img v-if="isDarkMode" src="@/assets/Logo-white.png" style=" width: 90%;  "></v-img>
            <v-img v-else src="@/assets/Logo-dark.png" style=" width: 90%;  "></v-img>
          </v-fade-transition>
        </div>
      </v-row>
        
      </v-col>
      <!-- <v-col md="1">
        <h3 @click="goToHome">
          {{ $t('Home') }}
        </h3>
      </v-col> -->
      <v-col md="2">
        <v-row>
          <v-col md="1"></v-col>
        <h3 @click="goToServices">
          {{ $t('Our Services') }}
        </h3>
      </v-row>
      </v-col>
      <!-- <v-col md="1">
        <h3 @click="goToAgents">
          {{ $t('Agents') }}
        </h3>
      </v-col>
      <v-col md="1">
        <h3 @click="goToClients">
          {{ $t('Clients') }}
        </h3>
      </v-col> -->
      <v-col md="1">
        <h3 @click="goToArticle">
          {{ $t('Blogs') }}
        </h3>
      </v-col>
      <!-- <v-col md="1">
        <h3 @click="goToAbout">
          {{ $t('About Us') }}
        </h3>
      </v-col> -->
      <v-col md="1">
        <h3 @click="goToContact">
          {{ $t('Contact Us') }}
        </h3>
      </v-col>
      <v-col class="justify-end" md="1">
        
          <!-- <v-btn class="mr-3" elevation="1" fab small text @click="changeLang">
            <v-icon> mdi-earth </v-icon>
          </v-btn> -->
          <v-btn  elevation="1" fab small text @click="changeMode">
            <v-icon v-if="isDarkMode"> mdi-white-balance-sunny </v-icon>
            <v-icon v-else> mdi-moon-waxing-crescent </v-icon>
          </v-btn>
        
      </v-col>
    </v-app-bar>
  </div>
</template>

<script>
// Components
import axios from "axios";
import { VHover, VListItem } from "vuetify/lib";
import i18n from '@/i18n';
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import debounce from "../../../../helpers/debounce";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },

  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
      "$store.state.isDarkMode": function () {
      this.isDarkMode = this.$store.state.isDarkMode;
      },
    },
  data: () => ({
    notifications: [],
    loading: false,
    count: null,
    isDarkMode: false,
    itemSearch: null,
    my_info: null,
    childrensLoading: false,
    childrens: [],
    currentTheme: "light",
    srcImg: '@/assets/Logo-white.png',
  }),

  computed: {
    ...mapGetters(["getRouteNameAddition"]),
    ...mapGetters({
      services: "services/getServices",
    }),
    ...mapState(["drawer"]),

  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",

    }),
    ...mapActions({
      fetchServices: "services/fetchServices",
      fetchHomeList: "home/fetchHomeList",
    }),

    changeLang() {
      if (this.$i18n.locale == 'en') {
        this.$i18n.locale = 'ar';
        localStorage.setItem('lang', 'ar');
        this.$vuetify.rtl = true;
      } else if (this.$i18n.locale == 'ar') {
        this.$i18n.locale = 'en';
        localStorage.setItem('lang', 'en');
        this.$vuetify.rtl = false;
      }
    },
    changeMode() {
      
      this.isDarkMode = !this.isDarkMode;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode);
      if (this.isDarkMode) {
        localStorage.setItem('theme', 'dark');
        document.documentElement.setAttribute('data-theme', 'dark');
        this.srcImg = '@/assets/Logo-white.png';
      } else {
        localStorage.setItem('theme', 'light');
        document.documentElement.setAttribute('data-theme', 'light');
        this.srcImg = '@/assets/Logo-dark.png';
      }
    },
    async goToHome() {
      this.fetchHomeList();
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });


    },
    goToServices() {
      // this.fetchServices();
      document.querySelector('.services-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToAgents() {
      document.querySelector('.agents-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToClients() {
      document.querySelector('.clients-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToArticle() {
      document.querySelector('.article-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToAbout() {
      document.querySelector('.about-content').scrollIntoView({ behavior: 'smooth' });
    },
    goToContact() {
      document.querySelector('.contact-content').scrollIntoView({ behavior: 'smooth' });
    },
  },
  async created() {
    // this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : this.$i18n.locale;
    // if (this.$i18n.locale == 'en') {

    //     this.$vuetify.rtl = false;
    //   } else if (this.$i18n.locale == 'ar') {

    //     this.$vuetify.rtl = true;
    //   }
    this.currentTheme = localStorage.getItem('theme') ? localStorage.getItem('theme') : this.currentTheme;
    if (this.currentTheme == "dark") {
      this.isDarkMode = true;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode);
      document.documentElement.setAttribute('data-theme', 'dark');
      this.srcImg = '@/assets/Logo-white.png';
    } else {
      this.isDarkMode = false;
      this.$store.commit("SET_DARK_MODE", this.isDarkMode);
      document.documentElement.setAttribute('data-theme', 'light');
      this.srcImg = '@/assets/Logo-dark.png';

    }




  },
};
</script>

<style>
h3 {
  cursor: pointer;
}

.appBarRtl {
  direction: ltr;
}

.appBar__newRequest-icon {
  border: 1px solid #66bb6a;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: #66bb6a !important;
  transition: 0.5s;
}

.appBar__newRequest-icon:hover {
  animation: scaleItem 0.5s linear infinite alternate;
  color: white !important;
  background: #66bb6a;
}

@keyframes scaleItem {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}
</style>
